import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/serializers"
import ContactForm from "../components/contactForm"

export const query = graphql`
  query($Slug: String) {
    sanityNews(slug: { current: { eq: $Slug } }) {
      seo {
        seo_title
        meta_description
      }
      title
      subTitle
      publishedAt
      _rawBody
      mainImage {
        alt
        image {
          asset {
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`

const News = props => {
  return (
    <Layout>
      {props.data.sanityNews.seo && (
        <SEO
          title={props.data.sanityNews.seo.seo_title}
          metaDescription={props.data.sanityNews.seo.meta_description}
          seoTitle={props.data.sanityNews.seo.seo_title}
        />
      )}

      <section className="insight_subpage subpage">
        <Img
          fluid={props.data.sanityNews.mainImage.image.asset.fluid}
          alt={props.data.sanityNews.mainImage.alt}
        ></Img>

        <div className="text_content">
          <h1>{props.data.sanityNews.title}</h1>
          <p className="date">Udgivet: {props.data.sanityNews.publishedAt}</p>
          <div>
            <p className="subtitle">{props.data.sanityNews.subTitle}</p>
            <BlockContent
              className={props.data.sanityNews.templateList}
              blocks={props.data.sanityNews._rawBody}
              projectId="ppd47c0u"
              dataset="production"
              serializers={serializers}
            />
          </div>
        </div>
      </section>
      <ContactForm></ContactForm>
    </Layout>
  )
}

export default News
